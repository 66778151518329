.brandNameWrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 4px 0;
}

.brandNameText {
  margin-bottom: 0;
}

.btn {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 20px;
}

.errorInput {
  text-transform: none;
  font-size: 11px;
  color: red;
  margin-bottom: 0;
  margin-top: 4px
}

.wrapperInput {
  height: 130px;
}