.dt-actions__container {
    display: flex;
    border-top: 1px solid #3E4154;
    width: 100%;
    flex-direction: column;
    gap: 20px;
    padding-top: 4px;
}

.dt-actions__container .main_actions-row {
    display: flex;
    align-items: center;
    gap: 16px;
}

.dt-actions__container .main_actions-row .btn-secondary_dark {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    background: transparent;
    color: #C4C6D0;
    text-align: center;
    font-family: Play;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.1px;
    gap: 6px;
    border: none;
    padding: 6px 16px 6px 12px;
}

.dt-actions__container .main_actions-row .btn-secondary_dark:hover {
    background-color: rgba(226,226,233,0.08);
    opacity: 0.92;
}

.dt-actions__container .dt-actions__selected-counter {
    color: #9A9EB8;
    font-family: Play;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.1px;
    padding-left: 4px;
}

/* .dt-actions__container .dt-actions__dropdown .dropdown-menu {
    background-color: #374057;
    width: 230px;
    min-width: unset;
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
    padding: 10px 15px;
    max-height: 400px;
    overflow-y: scroll;
    position: relative;
} */

.dt-actions__container .secondary-btn {
    color: #9BACFF;
    text-align: center;
    font-family: Play;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.1px;
    border: none;
    outline: none;
    max-width: fit-content;
    background: transparent;
    padding: 6px 12px;
}

.dt-actions__container .secondary-btn:hover {
    border-radius: 3px;
    background: #262A47;
}

.search-select_container {
    position: absolute;
    max-height: 300px;
    z-index: 10;
}

.dt-actions__container .dt-actions__dropdown .primary_menu {
    border-radius: 4px;
    background: #232640;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
    max-height: 300px;
    max-width: 250px;
    min-width: 250px;
    overflow-y: auto;
    padding: 0;
}

.dt-actions__container .dt-actions__dropdown .primary_menu::-webkit-scrollbar {
    width: 3px;
    height: 3px;
}

.dt-actions__container .dt-actions__dropdown .primary_menu::-webkit-scrollbar-track {
    background: transparent; 
}

.dt-actions__container .dt-actions__dropdown .primary_menu::-webkit-scrollbar-thumb {
    background: #4C4F69;
    border-radius: 4px;
}

.dt-actions__container .dt-actions__action-row {
    display: flex;
    align-items: center;
}

.dt-actions__container input.text-input {
    border-radius: 5px;
    border: 1px solid #727287;
    color: #C4C6D0!important;
    font-family: Play;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.5px;
    padding: 4px 4px 4px 10px;
    background-color: transparent;
    outline: none;
}

.dt-actions__container input.text-input:hover {
    border-color: #5F627F;
}

.dt-actions__container input.text-input:focus {
    border: 2px solid #9BACFF;
}

.dt-actions__container input.text-input::placeholder {
    color: #5F627F !important;
    font-family: Play;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.5px;
}

/* .dt-actions__container .dt-actions__dropdown .dropdown-item:hover {
    background-color: unset;
} */

.btn-default.bg-green {
    background-color: #008000;
}
.btn-default.bg-red {
    background-color: #822828
}

@media (max-width: 991px) {
    .dt-actions__container {
        justify-content: space-between;
    }
}