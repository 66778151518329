.tableWrapper {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  border: 1px solid #44474E;
  width: 100%;
  min-height: 410px;
  height: 100%;
  border-radius: 5px;
}

.allDepositsTitle {
  color: #E2E2E9;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; 
  letter-spacing: 0.15px;
}

.allDepositsTitleContainer {
  display: flex;
  padding: 12px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #44474E;
  gap: 20px;
}

.allDepositsStatisticDepositCont {
  height: 300px;
}

.allDepositsAnalysisBtn {
  width: 30px;
  height: 30px;
  outline: none;
  border: none;
  background-color: transparent;
}

.allDepositsStatisticDeposit {
  padding: 12px;
}

.radioContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 35px;
}

.noDataWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.noDataWrapperTitle {
  color: #C4C6D0;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; 
  letter-spacing: 0.25px;
  width: 100%;
  margin-bottom: 0;
  margin-top: 16px;
}