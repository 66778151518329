.brandNameContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.brandNameText {
  margin-bottom: 0;
}

.btnContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px
}

.btn {
  border: none;
  outline: none;
  background-color: transparent;
}

.btn:hover {
  background-color: #9795A1;
  border-radius: 100px;
  height: 26px;
}

.selector {
  padding: 10px;
  width: 100%;
  background-color: #2d3440;
  color: #ffffff;
  border: 1px solid #2d3440;
  border-bottom: 1px solid #000000;
  border-radius: 5px;
  outline: none;
}

.checkboxContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  padding-top: 8px;
}

.checkbox {
  width: 20px; 
  height: 20px;
  border: 1px solid #8e8888;
  border-radius: 5px;
  position: relative;
}
