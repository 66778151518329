.errorInput {
    text-transform: none;
    font-size: 11px;
    color: red;
    margin-bottom: 0;
    margin-top: 10px
}

.wrapperInput {
    height: 80px;
    justify-content: center;
}

.marginRight {
    margin-right: .5rem;
}