.inputContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    position: relative;
    margin: 0;
    padding: 0;
  }
  
  .inputTitle {
    position: absolute;
    top: -20px;
    font-size: 12px;
    font-style: normal;
    line-height: 16px;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: var(--RX-System-OnSurface);
  }
  
  .supportingText {
    margin-top: 4px;
    margin-bottom: 0;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;
    text-align: start;
    color: var(--RX-System-OnSurfaceVariant);
    position: absolute;
    top: 42px;
  }
  
  .disabled {
    opacity: 0.3;
  }
  
  .supportingTextError {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    color: var(--RX-System-Error);
    position: absolute;
    top: 40px;
    letter-spacing: 0.4px;
  }
  
  .form__error {
    color: var(--RX-System-Error);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.4px;
    text-align: left;
    position: absolute;
    top: 40px;
  }