.pageWrapper {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  width: calc(100% - 32px);
  min-height: 100vh;
  height: 100%;
  align-items: flex-start;
  justify-content: space-between;
  /* padding-left: 252px; */
  padding-left: 232px;
  padding-right: 0;
  gap: 20px;
}

.pageTitle {
  padding-top: 32px;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; 
  color: #E2E2E9;
  width: 100%;
}

.pageDirection {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  gap: 20px;
  width: 100%;
  width: calc(100% - 504px);
}

.filterWrapper {
  display: flex;
  position: sticky;
  right: 0;
  top: 70px;
  width: 504px;
  max-width: 504px;
  min-height: calc(100vh - 63px);
  border-left: 2px solid #44474E;
  z-index: 1;
}

.mobileFilterWrapper {
  display: flex !important;
  width: 100%;
  max-width: 100%;
  position: fixed;
  z-index: 1000;
  top: 70px;
  bottom: 0;
  left: 0;
  right: 0;
  background: #1d1f38;
  height: 100%;
  border: unset;
  height: 100%;
  min-height: 100%;
}

.formControlDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.formControlLabel {
  color: #E2E2E9;
  font-family: 'Play';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  margin-bottom: 0;
}

.tablesWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 24px;
  width: 100%;
} 

.allDepositsStatisticsTableCell {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  padding: 12px;
}

.allDepositsStatisticsTableCell:hover {
  background-color: rgba(196, 198, 208, 0.08);
}

.allDepositsStatisticsTableTitle {
  color: #E2E2E9;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; 
  letter-spacing: 0.25px;
  margin-bottom: 0;
  width: 100%;
}

.allDepositsStatisticsBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
}

.allDepositsStatisticsBarAmount {
  color: #C4C6D0;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.5px;
  margin-bottom: 0;
}

.allDepositsStatisticsBarPercentage {
  color: #9BBDFF;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; 
  letter-spacing: 0.5px;
  margin-bottom: 0;
}


.roundedFlag {
  display: inline-block;
  border-radius: 50%; 
  width: 40px;
}

.roundedFlag > * {
  width: 100%;
  height: 100%;
}

.datePickerWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.datePickerWrapper span {
  padding: 5px 10px;
  color: var(--CFD-theme-System-OnSurfaceVariant);
  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; 
  letter-spacing: 0.4px;
}

.actionBtnRow {
  display: none;
  gap: 8px
}

.refreshButton {
  background-color: #1d1f38;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
  border-radius: 3px;
  color: #C4C6D0;
  border: unset;
  padding: 6px 16px 6px 12px;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobileFilterIcon {
  background-color: #1d1f38;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
  border-radius: 3px;
  color: #C4C6D0;
  border: unset;
  padding: 6px 16px 6px 12px;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.mobileFilterIconActive {
  background-color: #274077;
}

.mobileAmountOfFilters {
  height: 16px;
  min-height: 16px;
  max-height: 16px;
  width: 16px;
  min-width: 16px;
  max-width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(214, 227, 255, 0.16);
  border-radius: 50%;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.5px;
  margin-left: 8px;
}

.mobileFilterIcon svg {
  margin-right: 12.61px;
}

@media screen and (max-width: 1700px) {
  .pageDirection {
    min-width: 900px;
  }
}

@media screen and (max-width: 1550px) {
  .tablesWrapper {
    flex-direction: column;
  }
  .pageDirection {
    min-width: 450px;
  }
}

@media screen and (max-width: 1239px) {
  .filterWrapper {
    display: none;
  }

  .actionBtnRow {
    display: flex;
  }

  .pageDirection {
    width: 100%;
  }

  .pageWrapper {
    width: calc(100% - 24px);
    padding-left: 224px;
    gap: 24px;
  }
}

@media screen and (max-width: 1175px) {
  .filterWrapper {
    min-height: calc(100vh + 63px);
  }
}

@media screen and (max-width: 768px) {
  .pageWrapper {
    width: 100%;
    padding-left: 24px;
    padding-right: 24px;
  }

  .pageDirection {
    min-width: unset;
  }

  .pageTitle {
    padding-top: 50px;
  }
}



