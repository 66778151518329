.filterDashboardWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.filterDashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.filterDashboardHeader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  gap: 8px;
  padding: 16px 8px 8px 16px;
  border-bottom: 1px solid #44474E;
}

.filterDashboardHeaderTitle {
  color: #E2E2E9;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; 
  letter-spacing: 0.15px;
  margin-bottom: 0;
}

.lineDivider {
  color: #44474E;
  background-color: #44474E;
  margin-bottom: 8px;
  margin-top: 8px;
  height: 2px;
}

.filterDashboardHeaderText {
  color: #C4C6D0;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; 
  letter-spacing: 0.4px;
  margin-bottom: 8px;
}

.filterDashboardPeriod {
  display: flex;
  flex-direction: column;
  padding: 8px 8px 8px 16px;
  gap: 8px;
  width: 100%;
  border-bottom: 1px solid #44474E;
}

.filterDashboardPeriodCont {
  display: flex;
  flex-direction: column;
  padding: 12px 8px 12px 16px;
  gap: 8px;
  width: 100%;
}

.filterDashboardPeriodTitle {
  width: 100%;
  text-align: start;
  color: #C4C6D0;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; 
  letter-spacing: 0.15px;
  margin-bottom: 0;
}

.filterDashboardPeriodTitleDate {
  width: 100%;
  text-align: start;
  color: #C4C6D0;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; 
  letter-spacing: 0.15px;
  margin-bottom: 0;
}

.filterDashboardPeriodChips {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-right: 50px;
  gap: 8px;
}

.filterDropDownContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 8px;
}

.filterDropDown {
  display: flex;
  padding: 8px 0;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 170px;
}

.filterDashboardFooter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px;
  position: absolute;
  bottom: 0;
  border-top: 1px solid #C4C6D0;
  background-color: #1d1f38;
}

.filterDashboardFooterFilterText {
  color: #E2E2E9;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; 
  letter-spacing: 0.1px;
  margin-bottom: 0;
}

.filterDashboardFooterBtnsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.filterDashboardFooterBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3E4759;
  outline: none;
  border: none;
  width: 30px;
  opacity: 1;
  height: 30px;
  cursor: pointer;
}

.filterDashboardFooterBtn:hover {
  opacity: 0.85;
}

.filterDashboardFooterBtn:disabled {
  opacity: 0.4;
}

input {
  color-scheme: dark;
}

input:disabled {
  cursor: not-allowed;
  opacity: 0.38;
}

.filterDashboardDatePicker {
  width: 100%;
  background: #1d1f38;
  border: 1px solid #C4C6D0;
  color: #C4C6D0;
  padding: 5px;
  border-radius: 5px;
}

.filterDashboardDatePicker:focus {
  border-color: transparent;
}

.containerFilterDashboardDatePicker {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 12px;

}

.wrapperFilterDashboardDatePicker {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}


.searchableCheckBoxListWrapper {
  display: flex;
  position: relative;
  width: 100%;
}
.searchableCheckBoxListWrapperBtnSpan {
  display: flex;
  align-items: center;
  justify-content: space-around;
}


.searchableCheckBoxListWrapperBtn {
  width: 100%;
  padding: 8px;
  background-color: #1d1f38;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
  color: #C4C6D0;
  border: none;
  outline: none;
  border-radius: 8px;
  height: 40px;
  cursor: pointer;
}

.searchableCheckBoxListWrapperBtn:hover {
  background-color: rgba(196, 198, 208, 0.08);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15),0px 2px 6px 2px rgba(0, 0, 0, 0.30);
}

.searchableCheckBoxListWrapperBtn:focus {
  background-color: rgba(196, 198, 208, 0.12);
}

.searchableCheckBoxListWrapperBtnSelected {
  width: 100%;
  padding: 8px;
  background-color: #3E4759;
  box-shadow: 0px 1px 2px 0px  rgba(0, 0, 0, 0.15), 0px 1px 3px 1px rgba(0, 0, 0, 0.30);
  color: #DAE2F9;
  border: none;
  outline: none;
  border-radius: 8px;
  height: 40px;
  cursor: pointer;
}

.searchableCheckBoxListWrapperBtnSelected:hover {
  background-color: #4A5366;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15),0px 2px 6px 2px rgba(0, 0, 0, 0.30);
}

.searchableCheckBoxListWrapperBtnSelected:focus {
  background-color: rgba(196, 198, 208, 0.12);
}

.searchableCheckBoxListDropdown {
  position: absolute;
  top: 40px;
  width: 280px;
  height: 250px;
  border-radius: 8px;
  overflow: auto;
  background-color: #222731;
  box-shadow: 0 8px 16px rgba(0,0,0,0.2);
  z-index: 1;
}

.searchableCheckBoxListDropdown.upwards {
  bottom: 100%; 
  top: auto; 
}


.searchableCheckBoxListInput {
  width: calc(100% - 16px);
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 8px;
  color: #DAE2F9;
  background-color: transparent;
}

.searchableCheckBoxListBtnsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.searchableCheckBoxListBtns {
  width: 120px;
  padding: 5px 10px;
  margin: 5px;
  border-radius: 3px;
  background-color: #3E4759;
  color: #DAE2F9;
  border: none;
  outline: none;
  cursor: pointer;
}

.searchableCheckBoxListHr {
  width: 100%;
  color: #44474E;
}

.searchableCheckBoxListUl {
  list-style: none;
  padding: 0;
  margin: 0;
}

.searchableCheckBoxListLI {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  cursor: pointer;
}

.searchableCheckBoxListLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: #E2E2E9;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  cursor: pointer;
}

.searchableCheckBoxListCheckbox {
  cursor: pointer;
}

.filterDashboardContainer {
  max-height: calc(100vh - 200px);
  min-height: calc(100vh - 200px);
  overflow: auto;
}

.filterDashboardHeaderRow {
  display: flex;
  align-items: center;
  gap: 16px;
}

.closeBtn {
  border: unset;
  background-color: unset;
  outline: unset;
  cursor: pointer;
  display: none;
  color: #E2E2E9;
}

@media screen and (max-width: 1239px) {
  .closeBtn {
    display: block;
  }

  .filterDashboardHeaderText {
    display: none;
  }

  .filterDashboardFooter {
    position: fixed;
  }
}

