.errorInput {
    text-transform: none;
    font-size: 11px;
    color: red;
    margin-bottom: 0;
    margin-top: 4px
}

.wrapperInput {
    height: 80px;
    width: 160px;
}

.titleContainer {
    width: 100%;
    text-align: start;
}
