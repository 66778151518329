.selector {
  padding: 10px;
  margin-top: 10px;
  width: 100%;
  background-color: #2d3440;
  color: #ffffff;
  border: 1px solid #2d3440;
  border-bottom: 1px solid #000000;
  border-radius: 5px;
  outline: none;
}

.commentInput {
  width: 100%;
  background-color: #1d2038;
  border-radius: 10px;
  color: whitesmoke;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  border: none;
  outline: none;
}