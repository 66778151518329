.defaultButton {
    border-radius: 3px;
    border: 1px solid #4C4F69;
    background-color: transparent;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.10000000149011612px;
    text-align: center;
    color: #9BACFF;
    width: 100%;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
}

.defaultButton:hover {
    background: #262A47;
    color: #9BACFF;
}

.btnContainer {
    width: 100%;
    padding: 9px 12px;
    border-bottom: 1px solid #4C4F69;
}