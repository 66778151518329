.chat__message-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.chat__message-row .button__action-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 0 12px;
}

.chat__message-row .chat__unread-dot {
    width: 10px;
    height: 10px;
    max-width: 10px;
    max-height: 10px;
    border-radius: 50%;
    background-color: #eee;
    margin-top: 20px;
    margin-left: 5px;
}
.chat-accordion-list .accordion-item {
    background-color: #16202e;
    color: #fff;
}
.chat-accordion-list .accordion-item .accordion-body {
    padding: unset;
}

.chat-accordion-list .accordion-item .accordion-header {
    border-bottom: 1px solid #1f2c3e;
}

.chat-accordion-list .accordion-item .accordion-button {
    background-color: #16202e;
    color: #fff;
}

.chat-accordion-list .accordion-item .accordion-button::after {
    color: #fff;
}

.chat-input__upload {
    margin-right: 10px;
    position: relative;
}

.chat-input__upload .chat-input__filename {
    position: absolute;
    bottom: -25px;
    left: -5px;
    font-size: 10px;
    display: flex;
}

.chat-input__upload .chat-input__filename .truncate-text {
    width: inherit;
    max-width: 70px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    display: block;
}

.chat-input__upload label {
    cursor: pointer;
}

.chat-input__upload input {
    display: none;
}

.chat-input__filename {
    color: #fff;
}

.chatBonIconContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* margin: 10px 20px; */
    width: 30px;
    height: 30px;
    border-radius: 100px;
}

.chatListBinIconContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 10px;
    width: 30px;
    height: 30px;
    border-radius: 100px;
}

.chatListBinIconContainer:hover {
    background-color: lightslategray;
}

.chatBonIconContainer:hover {
    background-color: lightslategray;
}

.chatMessageWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}