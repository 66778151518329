.backgroundWrapper {
  display: flex !important;
  position: fixed;
  z-index: 999;
  background-color: #0000003b;
  width: 100%;
  height: 100%;
}

.filterWrapper {
  background-color: transparent;
  min-width: 535px;
  overflow-x: scroll;
  border-left: 1px solid #44474E;
}

.mobileFilterWrapper {
  display: flex !important;
  width: 100%;
  max-width: 100%;
  position: fixed;
  z-index: 1000;
  top: 70px;
  bottom: 0;
  left: 0;
  right: 0;
  background: #1d1f38;
  height: 100%;
  border: unset;
  height: 100%;
  min-height: 100%;
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.tabletFilterWrapper {
  display: flex !important;
  position: fixed;
  z-index: 1000;
  top: 70px;
  bottom: 0;
  right: 0;
  background: #1d1f38;
  border: unset;
  animation: slideInRight 0.3s forwards;
  overflow: hidden;
}

.filterDashboardWrapper {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  min-width: inherit;
  font-family: Inter, sans-serif;
  font-feature-settings: 'liga' 1, 'calt' 1;
  height: 100%;
}

.filterDashboardWrapper::-webkit-scrollbar {
  width: 4px;
  cursor: pointer;
}

/* Track */
.filterDashboardWrapper::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 100px;
}

/* Handle */
.filterDashboardWrapper::-webkit-scrollbar-thumb {
  background: #44474E;
  border-radius: 100px;
}

.filterDashboardContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 531px;
  height: 100%;
}

.filterDashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 531px;
  position: absolute;
  top: 0;
}

.filterDashboardHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #44474E;
}

.filterDashboardHeaderDescription {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;
  padding: 8px 8px 8px 16px;
}

.filterDashboardHeaderTitle {
  color: #E2E2E9;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.15px;
  margin-bottom: 0;
}

.filterDashboardHeaderText {
  color: #C4C6D0;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
  margin-bottom: 8px;
}

.filterDashboardHeaderSaveBtnWrapper {
  padding: 10px;
}

.filterDashboardHeaderSaveBtnWrapper button {
  padding: 5px 10px;
  border: none;
  border-radius: 3px;
  background: #9BBDFF;
  color: #09305F;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
}

.filterDashboardPeriodContainer {
  display: flex;
  flex-direction: column;
  padding: 12px 8px 12px 16px;
  gap: 8px;
  width: 100%;
}

.filterDashboardPeriodTitle {
  width: 100%;
  text-align: start;
  color: #C4C6D0;
  margin-bottom: 0;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
}

.filterDashboardPeriodChips {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.filterDropDownContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 16px;
}

.filterDropDown {
  display: flex;
  padding: 8px 0;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 170px;
}

.spacing {
  height: 100px;
}

.dividerWrapper {
  padding: 0px 15px;
  width: 100%;
  height: 1px;
}

.divider {
  width: 100%;
  height: 1px;
  background: #44474E;
}

.filterDashboardFooter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 540px;
  padding: 16px;
  position: fixed;
  bottom: 0;
  background-color: #1E1F38;
  border-top: 1px solid #44474E;
}

.filterDashboardFooterFilterText {
  color: #E2E2E9;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  margin-bottom: 0;
}

.filterDashboardFooterBtnsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.filterDashboardFooterBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3E4759;
  outline: none;
  border: none;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.filterDashboardFooterBtn:hover {
  background-color: #4A5366;
}

.filterDashboardFooterBtn:active {
  background-color: #515A6C;
}

.filterDashboardFooterBtn:disabled {
  background-color: #31353D;
  cursor: not-allowed;
  opacity: 0.6;
}

input {
  color-scheme: dark;
}

input:disabled {
  cursor: not-allowed;
  opacity: 0.38;
}

.filterDashboardDatePicker {
  width: 240px;
  background: transparent;
  border: 1px solid #8E9099;
  color: white;
  padding: 5px;
  border-radius: 5px;
}

.filterDashboardDatePicker:focus {
  border-color: transparent;
}

.containerFilterDashboardDatePicker {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

}

.wrapperFilterDashboardDatePicker {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}


.searchableCheckBoxListWrapper {
  display: flex;
  position: relative;
  width: 100%;
}

.searchableCheckBoxListWrapperBtn {
  width: 100%;
  padding: 8px;
  background-color: #3E4759;
  color: #E2E2E9;
  border: none;
  outline: none;
  border-radius: 8px;
  height: 40px;
  cursor: pointer;
}

.searchableCheckBoxListDropdown {
  position: absolute;
  top: 40px;
  width: 300px;
  min-height: 250px;
  max-height: 400px;
  border-radius: 8px;
  overflow: auto;
  background-color: #222731;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.searchableCheckBoxListInput {
  width: calc(100% - 16px);
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 8px;
  color: #DAE2F9;
  background-color: transparent;
}

.searchableCheckBoxListBtnsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.searchableCheckBoxListBtns {
  width: 120px;
  padding: 5px 10px;
  margin: 5px;
  border-radius: 3px;
  background-color: #3E4759;
  color: #DAE2F9;
  border: none;
  outline: none;
  cursor: pointer;
}

.searchableCheckBoxListHr {
  width: 100%;
  color: #44474E;
}

.searchableCheckBoxListUl {
  list-style: none;
  padding: 0;
  margin: 0;
}

.searchableCheckBoxListLI {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  cursor: pointer;
}

.searchableCheckBoxListLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: #E2E2E9;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  cursor: pointer;
}

.searchableCheckBoxListCheckbox {
  cursor: pointer;
}

@media screen and (max-width: calc(904px + 220px)) {
  .filterDashboardWrapper {
    position: absolute;
    background-color: #1E1F38;
    width: -webkit-fill-available;
    overflow-x: scroll;
  }

  .filterDashboardContainer {
    width: -webkit-fill-available;
  }

  .filterDashboard {
    margin-top: 30px;
  }
}

@media screen and (max-width: calc(540px)) {
  .filterDashboardPeriodChips {
    width: 300px;
  }

  .wrapperFilterDashboardDatePicker {
    width: 320px;
  }

  .containerFilterDashboardDatePicker {
    gap: 6px;
    width: 300px;
  }

  .filterDashboardHeader {
    justify-content: flex-start;
  }

  .filterDashboardHeaderDescription {
    width: 250px;
  }

  .filterDashboardFooter {
    width: 100%;
    left: 0;
  }
}

@media screen and (max-width: calc(350px)) {
  .filterDashboardPeriodChips {
    width: 210px;
  }

  .wrapperFilterDashboardDatePicker {
    width: 224px;
  }

  .containerFilterDashboardDatePicker {
    gap: 4.2px;
    width: 210px;
  }

  .filterDashboardHeader {
    justify-content: flex-start;
  }

  .filterDashboardHeaderDescription {
    width: 175px;
  }

  .filterDashboardFooter {
    width: 100%;
    left: 0;
  }
}