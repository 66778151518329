.segmentButtons {
  display: flex;
  border: 1px solid #8E9099;
  border-radius: 5px;
  overflow: hidden;
  width: fit-content;
  background-color: transparent;
}

.button {
  min-width: 110px;
  display: flex;
  justify-content: center;
  padding: 8px 16px;
  border: none;
  background: none;
  color: #E2E2E9;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  flex: 1;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.10000000149011612px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.leadsButton {
  border-right: 1px solid #8E9099;
}

.buttonContent {
  display: flex;
  align-items: center;
  gap: 4px;
}

.button:hover {
  background-color: #DAE2F914;
}

.button:disabled {
  border-color: #8E90991F;
  color: #E2E2E9;
  cursor: not-allowed;
  background-color: transparent;
  opacity: 0.6;
}

.active {
  background-color: #3E4759;
  color: #DAE2F9;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.10000000149011612px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}