.calendarWarpper {
    width: 100%;
    height: 100%;
    padding: 10px 30px;
}

.calendar {
    width: 100%;
    height: 100%;
}

.calendarBody {
    width: 100%;
    height: 90%;
    background-color: #252a4750;
}

.week {
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: space-between;
}

.cell {
    position: relative;
    width: 15%;
    height: 100%;
    border: 1px solid #6e7f9580;
}

.cellHeader {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 10px;
}

.day {
    height: 44px;
    width: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}