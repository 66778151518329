.calendarSubHeader {
    padding: 20px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.selectorBlock {
    display: flex;
    gap: 10px;
    align-items: center;
}

.selector {
    background: #374057;
    color: #fff;
    border: 1px solid #374057;
    outline: none;
    border-radius: 5px;
    padding: 3px;
    margin-left: 5px;
    min-width: 100px;
    max-width: 380px;
}

@media (max-width: 575px) {
    .calendarSubHeader>div {
        width: 100%;
        margin: 10px 0;
    }

    .selector {
        width: 100%;
    }
}