.ftdContainer {
  position: relative;
  width: 100%;
  color: #E2E2E9;
  border-radius: 8px;
}

.ftdTitleWrapper {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
}

.ftdTitle {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.25px;
}

.downloadIcon {
  margin: 4px;
  cursor: pointer;
}

.ftdHeader {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  margin-top: 12px;
  border-bottom: 1px solid #44474E;
  width: 100%;
  max-width: 100%;
  min-width: max-content;
}

.ftdHeaderItem {
  display: flex;
  font-size: 12px;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 0.5px;
  padding: 5px 16px;
  white-space: nowrap;
  align-items: center;
  width: 150px;
}

.ftdHeaderItem:hover {
  cursor: pointer;
  background: #9BBDFF14;
}



.ftdTable {
  border-bottom: 1px solid #44474E;
  min-height: 200px;
  max-height: 400px;
  overflow: auto;
  width: 100%;
  max-width: 100%;
  min-width: max-content;
}

.ftdGridRow {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
}
.ftdGridRow:hover {
  background: #C4C6D014;
}

.ftdGridItem {
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 0.5px;
  padding: 10px 16px;
  overflow :hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 150px;
}

.ftdNetDepositRed {
  color: #FCB3AC;
}

.ftdNetDepositGreen {
  color: #90D4B6;
}



.pagination {
  margin: 10px 10px 16px 10px;
  display: flex;
  justify-content: flex-end;
}

.rowsPerPageWrapper {
  display: flex;
  justify-content: space-between;
  width: 160px;
  margin-top: 4px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
}

.rowsPerPageSelector {
  outline: none;
  width: 40px;
  color: #65676F;
  background: transparent;
  border-top: #292C34;
  border-left: #292C34;
  border-right: #292C34;
  cursor: pointer;
}

.pageInfo {
  margin-left: 35px;
  margin-top: 4px;
}

.pageInfo span {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
}

.pageButtonsWrapper {
  display: flex;
  justify-content: space-between;
  width: 90px;
  margin-top: 8px;
  margin-left: 65px;
}

.noDataWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
}

.noDataWrapperTitle {
  color: #C4C6D0;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; 
  letter-spacing: 0.25px;
  width: 100%;
  margin-bottom: 0;
  margin-top: 16px;
}

@media screen and (max-width: calc(700px)) {
  .rowsPerPageWrapper {
    width: auto;
  }
  .pagination {
    justify-content: center;
    gap: 20px;
  }
  .pageInfo {
    margin-left: 0px;
  }
  .pageButtonsWrapper {
    margin-left: 0px;
  }
}
