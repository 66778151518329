.wrapper {
  display: flex;
  flex-direction: row;
  max-height: 90vh;
}

.dashboardWrapper {
  color: #E2E2E9;
  padding: 31px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-family: Inter, sans-serif;
  font-feature-settings: 'liga' 1, 'calt' 1;
  width: -webkit-fill-available;
  overflow-x: auto;
}

.dashboardWrapper::-webkit-scrollbar {
  width: 4px;
  cursor: pointer;
}

/* Track */
.dashboardWrapper::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 100px;
}

/* Handle */
.dashboardWrapper::-webkit-scrollbar-thumb {
  background: #44474E;
  border-radius: 100px;
}

.adaptiveFilterWrapper {
  display: none;
}

@media screen and (max-width: calc(1220px + 220px)) {
  .adaptiveFilterWrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 4px;
    margin-bottom: 10px;
  }

  .filterAdaptiveBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    padding: 0 10px;
    background-color: #3E4759;
    color: #C4C6D0;
    outline: none;
    border: none;
    border-radius: 3px;
    width: auto;
    min-width: 80px;
    height: 30px;
    cursor: pointer;
  }

  .activeFilterAdaptiveBtn {
    background-color: #284077;
  }

  .filterAdaptiveReloadBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #3E4759;
    outline: none;
    border: none;
    border-radius: 3px;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  .filterAdaptiveAmountIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    padding: 8px;
    border-radius: 8px;
    background: #d6e3ff29;
  }

  .filterAdaptiveAmountIcon span {
    font-family: Inter;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.5px;
  }
}


.item {
  border: 1px solid #44474E;
  border-radius: 5px;
  margin-bottom: 26px;
}

.title {
  font-size: 24px;
  margin-bottom: 30px;
  letter-spacing: 0.05px;
}

.allDepositsStatisticsTableCell {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  padding: 12px;
}

.allDepositsStatisticsTableCell:hover {
  background-color: rgba(196, 198, 208, 0.08);
}

.allDepositsStatisticsTableTitle {
  color: #E2E2E9;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  margin-bottom: 0;
  width: 100%;
}

.allDepositsStatisticsBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
}

.allDepositsStatisticsBarAmount {
  color: #C4C6D0;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.5px;
  margin-bottom: 0;
}

.allDepositsStatisticsBarPercentage {
  color: #9BBDFF;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.5px;
  margin-bottom: 0;
}


.roundedFlag {
  display: inline-block;
  border-radius: 50%;
  width: 40px;
}

.roundedFlag>* {
  width: 100%;
  height: 100%;
}

.datePickerWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.datePickerWrapper span {
  padding: 5px 10px;
  color: var(--CFD-theme-System-OnSurfaceVariant);
  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
}