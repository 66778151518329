.dt-actions__dropdown .dropdown-menu {
    background-color: #374057;
    width: 200px;
    min-width: unset;
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
    padding: 10px 15px;
}

.dt-actions__dropdown .dropdown-item {
    color: #fff;
    text-transform: uppercase;
    padding: 5px 0;
    font-size: 14px;
    cursor: pointer;
}

.dt-actions__dropdown .dropdown-item:hover {
    background-color: unset;
}

.dt-actions__dropdown.secondary button {
    width: 100%;
    max-width: 100%;
}

.dt-filters__btn-row {
    display: flex;
    margin-top: 15px;
    justify-content: center;
    align-items: center;
    gap: 12px;
}

.dt-actions__dropdown .dt-filters__filters-selector {
    overflow: hidden;
    text-overflow: ellipsis;
}
.dt-filters__btn-row .secondary-btn {
    color: #9BACFF;
    text-align: center;
    font-family: Play;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.1px;
    border: none;
    outline: none;
    max-width: fit-content;
    background: transparent;
    padding: 6px 12px;
}
.dt-filters__btn-row .secondary-btn:hover {
    border-radius: 3px;
    background: #262A47;
}