.calendarWarpper {
    width: 100%;
    height: 100%;
    padding: 10px 30px;
}

@media (max-width: 1199px) {
    .calendarWarpper {
        padding: 10px;
    }
}