.wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 10px 0;
}

.itemWrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.addBtn {
  margin-left: 10px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
}

.inputKey {
  width: 40%;
  border: 1px solid #2d3440;
  background-color: #2d3440;
  outline: none;
  box-shadow: none;
  color: #ffffff;
  min-height: 45px;
  display: block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  appearance: none;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  padding: .375rem .75rem;
}

.inputValue {
  width: 100%;
  border: 1px solid #2d3440;
  background-color: #2d3440;
  outline: none;
  box-shadow: none;
  color: #ffffff;
  min-height: 45px;
  display: block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  appearance: none;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  padding: .375rem .75rem;
}
