.container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.header {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.time {
    font-size: 12px;
    font-weight: 100;
}

.body {
    display: flex;
    justify-content: space-between;
    width: 100%;
}