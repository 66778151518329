.select-row {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}

.select-row .form-group {
    flex: 1;
    min-width: 200px;
}

@media (max-width: 768px) {
    .select-row {
        flex-direction: column;
    }
}