.wrapper {
    min-height: 300px;
    height: fit-content;
    padding: 10px;
    display: flex;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    position: relative;
  }
  
  .headerWrapper {
    width: 100%;
    padding: 5px;
    display: flex;
    justify-content: space-between;
  }
  
  .bodyWrapper {
    height: 200px;
    padding: 10px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }