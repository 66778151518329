.wrapper {
  height: 600px;
  overflow-x: auto;
}

.selector {
  padding: 10px;
  margin-top: 10px;
  width: 100%;
  background-color: #2d3440;
  color: #ffffff;
  border: 1px solid #2d3440;
  border-bottom: 1px solid #000000;
  border-radius: 5px;
  outline: none;
}
