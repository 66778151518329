.mainBtnRow {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  gap: 4px;
  padding-bottom: 24px;
}

.mainBtnRow .btn {
    display: flex;
    height: 58px;
    min-width: 56px;
    flex-direction: column;
    align-items: center;
    gap: var(--space-050, 4px);
    flex: 1 0 0;
    border-radius: 3px;
    background: #29304D;
    color: #9BACFF;
    text-align: center;
    font-family: Play;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 145.455% */
    letter-spacing: 0.5px;
    border: none;
    padding: 8px 8px 6px 8px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    border-radius: 3px;
}

.mainBtnRow .btn:hover {
    background: #333B5C;
    color: #9BACFF;
}

.mainBtnRow svg {
    max-width: 24px;
}

.smallBtnRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
}

.smallBtnRow .btnRow {
    display: flex;
    align-items: center;
    gap: 4px;
}

.smallBtnRow .btnRow .btn {
    color: #9BACFF;
    text-align: center;
    font-family: Play;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.1px;
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 3px;
    height: 28px;
}

.smallBtnRow .btnRow .btn:hover {
    background: #2D3654;
}

.smallBtnRow .btnRow .svgSize {
    max-width: 18px;
    max-height: 18px;
}

@media (max-width: 1204px) {
    .smallBtnRow {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 15px;
    }

    .smallBtnRow .btnRow {
        flex-direction: column;
        align-items: flex-start;
    }
}