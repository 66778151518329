.analysis {
  display: flex;
  flex-direction: column;
}
.analysisTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 17px;
  padding-right: 17px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 16px;
  border-bottom: 1px solid #44474E;
  letter-spacing: 0.25px;
}
.analysisData {
  display: flex;
  justify-content: space-evenly;
  height: 85px;
  padding: 17px;
}
.analysisDataItem {
  width: 250px;
}
.downloadIcon {
  cursor: pointer;
  margin: 3px;
}
.itemTitle {
  font-weight: 100;
  font-size: 12px;
  color: #8E9099;
}
.itemValue {
  font-size: 18px;
  color: #E2E2E9;
}

@media screen and (max-width: calc(500px)) {
  .itemTitle {
    font-size: 10px;
  }
  .itemValue {
    font-size: 12px;
  }
}
