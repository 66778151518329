
.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.titleContainer {
  display: flex;
  padding: 12px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #44474E;
  gap: 20px;
}

.title {
  color: #E2E2E9;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; 
  letter-spacing: 0.15px;
}

.downloadBtn {
  width: 30px;
  height: 30px;
  outline: none;
  border: none;
  background-color: transparent;
}

.chartsContainer {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #44474E;
}

.radioContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.mapChartWrapper {
  padding: 12px;
  width: 100%;
  border-right: 1px solid #44474E;
}

.mapChartMapContainer {
  min-height: 290px;
}

.allDepositTotal {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 5px;
}

.totalAmountTitle {
  color: #8E9099;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; 
  letter-spacing: 0.5px;
}

.totalAmount {
  margin-left: 8px;
  color: #E2E2E9;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; 
  letter-spacing: 0.4px;
}

.allDepositsStatisticsTable {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 340px;
  overflow: auto;
}

.noDataWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.noDataWrapperTitle {
  color: #C4C6D0;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; 
  letter-spacing: 0.25px;
  width: 100%;
  margin-bottom: 0;
  margin-top: 16px;
}

@media screen and (max-width: calc(500px)) {
  .chartsContainer {
    flex-direction: column;
  }
  .mapChartWrapper {
    border-right: none;
  }
  .allDepositsStatisticsTable {
    border-top: 1px solid #44474E;
  }
}
