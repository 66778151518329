.notificationContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;

  padding-block: 0 16px;

  border-radius: 4px;
  border: 1px solid #262C38;
  background: #222731;
  box-shadow: 0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px 0px rgba(0, 0, 0, 0.30);
}

.notificationHeader {
  width: 100%;
  padding-inline: 12px;
  padding-block: 6px;
  background-color: #262C38;
}

.notificationActionHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.notificationHeaderActions {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 12px;
  font-weight: 400;
}

.notificationDate {
  text-align: right;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  color: #8E9099;
}

.notificationTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 16px;
  width: 100%;
}

.pt-12px {
  padding-top: 12px;
}

.notificationTitleText {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  font-size: 14px;
  color: #C4C6D0;
  font-weight: 500;
}

.closeButton {
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
}

.notificationContent {
  width: 100%;
  padding-inline: 16px;
}

.notificationDescription {
  padding-top: 6px;
  /* padding-left: 41px; */
  color:#C4C6D0;
  font-size: 14px;
  font-weight: 300;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-clamp: 2;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  box-orient: vertical;
  -moz-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.additionalInfo {
  color: #9BBDFF;
  font-size: 12px;
  font-weight: 400;
  /* padding-left: 42px; */
  padding-top: 12px;
  cursor: pointer;
}

.notificationActionIcon {
  cursor: pointer;
}

.firstLabel {
  color: #8E9099;
  font-weight: 500;
  font-size: 11px;
}

.secondLabel {
  color: #9BBDFF;
}
