.pageTitle {
  padding-top: 32px;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; 
  color: #E2E2E9;
  width: 100%;
}

.salesAnalysis {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  border: 1px solid #44474E;
  width: 100%;
  max-height: 250px;
  height: 100%;
  border-radius: 5px;
}

.salesAnalysisTitleWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 12px 12px 12px 16px;
}

.salesAnalysisBtn {
  width: 30px;
  height: 30px;
  outline: none;
  border: none;
  background-color: transparent;
}

.salesAnalysisTitle {
  color: #E2E2E9;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; 
  letter-spacing: 0.15px;
}

.salesAnalysisContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-top: 1px solid #44474E;
  padding: 16px 16px 24px 16px;
  gap: 20px;
}

.salesAnalysisRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  gap: 20px;
}

.salesAnalysisCell {
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-width: 230px;
  width: 100%;
}

.salesAnalysisCellTitle {
  color: #8E9099;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.5px;
  margin-bottom: 0;
}

.salesAnalysisCellAmount {
  color: #E2E2E9;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; 
  letter-spacing: 0.15px;
  margin-bottom: 0;
}

.salesAnalysisCellTitleLower {
  color: #8E9099;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; 
  letter-spacing: 0.5px;
  margin-bottom: 0;
}

.salesAnalysisCellAmountLower {
  color: #E2E2E9;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; 
  letter-spacing: 0.5px;
  margin-bottom: 0;
}

.mobileDivider {
  display: none;
  border-top: 1px solid #44474E;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .salesAnalysis {
    max-height: unset;
  }

  .salesAnalysisRow {
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 16px;
  }

  .salesAnalysisCell {
    width: calc(50% - 32px);
  }

  .mobileDivider {
    display: block;
  }
}