
.searchableCheckBoxListWrapper {
  display: flex;
  width: 100%;
  margin-left: 8px;
}

.searchableCheckBoxListWrapperBtn {
  background-color: #3E4759;
  color: #E2E2E9;
  border: none;
  outline: none;
  border-radius: 4px;
  height: 23px;
  cursor: pointer;
}

.selectedSearchableCheckBoxListWrapperBtn {
  background-color: #284077;
  color: #E2E2E9;
  border: none;
  outline: none;
  border-radius: 4px;
  height: 23px;
  cursor: pointer;
}


.searchableCheckBoxListDropdown {
  position: absolute;
  top: 85px;
  width: 300px;
  min-height: 250px;
  max-height: 400px;
  border-radius: 8px;
  overflow: auto;
  background-color: #222731;
  box-shadow: 0 8px 16px rgba(0,0,0,0.2);
  z-index: 1;
}
