.customPagination {
    background: transparent;
    display: flex;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    box-sizing: border-box;
    padding-right: 8px;
    padding-left: 8px;
    width: 100%;
    color: #fff;
    font-size: 13px;
    min-height: 56px;
}

.title {
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0 4px;
}

.selectContainer {
  position: relative;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  font-size: inherit;
  color: inherit;
  margin-top: 1px;
}

.sSelect {
  cursor: pointer;
  height: 24px;
  max-width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 8px;
  padding-right: 24px;
  box-sizing: content-box;
  font-size: inherit;
  color: inherit;
  border: none;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  direction: ltr;
  -ms-flex-negative: 0;
}

.input {
  cursor: pointer;
  height: 24px;
  max-width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 8px;
  padding-right: 24px;
  box-sizing: content-box;
  font-size: inherit;
  color: inherit;
  border: none;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  direction: ltr;
  -ms-flex-negative: 0;
  width: 30px;
}

.selectContainer > svg {
  top: 0;
  right: 0;
  color: inherit;
  position: absolute;
  fill: currentColor;
  width: 24px;
  height: 24px;
  display: inline-block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}

.btnRow {
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 4px;
    white-space: nowrap;
}

.btnRow > button {
  position: relative;
    display: block;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: none;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    padding: 8px;
    margin: px;
    cursor: pointer;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    color: #FFFFFF;
    fill: #FFFFFF;
    background-color: transparent;
}

.btnRow > button:hover:not(:disabled) {
  background-color: rgba(255, 255, 255, .12);
}