.authDataCell, .additionalParamsCell {
  display: flex;
  flex-direction: column;
}

.actionCell {
  display: flex;
  gap: 5px;
}

.enableBtn {
  color: #fff;
  background-color: #28a745;
  border: none;
  border-color: #28a745;
  border-radius: 50px;
  min-width: 50px;
  padding: 6px 0px;
}

.disableBtn {
  color: #fff;
  background-color: #6c757d ;
  border: none;
  border-color: #6c757d ;
  border-radius: 50px;
  min-width: 50px;
  padding: 6px 0px;
}

.editBtn {
  color: #fff;
  background-color: #ffc107;
  border: none;
  border-color: #ffc107;
  border-radius: 50px;
  min-width: 40px;
  padding: 6px 0px;
}

.deleteBtn {
  color: #fff;
  background-color: #f2214c;
  border: none;
  border-color: #f2214c;
  border-radius: 50px;
  min-width: 32px;
  padding: 6px 0px;
}

.actionContainerHeader {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.actionTitleHead {
  display: flex;
  width: 140px; 
  justify-content: flex-start;
}