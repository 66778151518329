.tableWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 640px;
  min-width: 400px;
}

.allDeposits {
 display: flex;
 flex-direction: column;
 width: 100%;
 min-width: 400px;
 border: 1px solid #44474E;
 border-radius: 5px;
}

.allDepositsTitleContainer {
  display: flex;
  padding: 12px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #44474E;
  gap: 20px;
}

.allDepositsAnalysisBtn {
  width: 30px;
  height: 30px;
  outline: none;
  border: none;
  background-color: transparent;
}

.allDepositsTitle {
  color: #E2E2E9;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; 
  letter-spacing: 0.15px;
}

.radioContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.allDepositsStatisticDeposit {
  padding: 12px;
}

.allDepositTotal {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #44474E;
}

.totalAmountTitle {
  color: #8E9099;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; 
  letter-spacing: 0.5px;
}

.totalAmount {
  margin-left: 8px;
  color: #E2E2E9;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; 
  letter-spacing: 0.4px;
}

.depositsByCountryMapContainer {
  height: 290px;
}

.allDepositsStatisticsTable {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 155px;
  overflow: auto;
}

.noDataWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.noDataWrapperTitle {
  color: #C4C6D0;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; 
  letter-spacing: 0.25px;
  width: 100%;
  margin-bottom: 0;
  margin-top: 16px;
}

@media screen and (max-width: 768px) {
  .allDeposits {
    min-width: unset;
  }

  .tableWrapper {
    min-width: unset;
  }
}